module.exports = {
  init() {
    const cont = document.querySelector('.cont');
    const elsArr = [].slice.call(document.querySelectorAll('.el'));
    const closeBtnsArr = [].slice.call(document.querySelectorAll('.el__close-btn'));

    setTimeout(() => {
      cont.classList.remove('s--inactive');
    }, 200);

    elsArr.forEach((el) => {
      el.addEventListener('mouseup', () => {
        if (el.classList.contains('s--active')) return;
        cont.classList.add('s--el-active');
        el.classList.add('s--active');
      });
    });

    closeBtnsArr.forEach((btn) => {
      btn.addEventListener('mouseup', (e) => {
        e.stopPropagation();
        cont.classList.remove('s--el-active');
        document.querySelector('.el.s--active').classList.remove('s--active');
      });
    });
  }
};
