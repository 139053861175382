/* eslint-disable no-param-reassign */
/* eslint-disable class-methods-use-this */
export default class WordAnimation {
  constructor(classname) {
    this.words = document.getElementsByClassName(classname);
    this.wordArray = [];
    this.currentWord = 0;
    this.words[this.currentWord].style.opacity = 1;

    for (let i = 0; i < this.words.length; i++) {
      this.splitLetters.bind(this)(this.words[i]);
    }
    setInterval(this.changeWord.bind(this), 4000);
  }

  changeWord() {
    const cw = this.wordArray[this.currentWord];
    const nw = this.currentWord === this.words.length - 1 ? this.wordArray[0] : this.wordArray[this.currentWord + 1];
    for (let i = 0; i < cw.length; i++) {
      this.animateLetterOut(cw, i);
    }

    for (let i = 0; i < nw.length; i++) {
      nw[i].className = 'letter behind';
      nw[0].parentElement.style.opacity = 1;
      this.animateLetterIn(nw, i);
    }

    this.currentWord = this.currentWord === this.wordArray.length - 1 ? 0 : this.currentWord + 1;
  }

  splitLetters(word) {
    const content = word.innerHTML;
    word.innerHTML = '';
    const letters = [];
    for (let i = 0; i < content.length; i++) {
      const letter = document.createElement('span');
      letter.className = 'letter';
      letter.innerHTML = content.charAt(i);
      word.appendChild(letter);
      letters.push(letter);
    }

    this.wordArray.push(letters);
  }

  animateLetterOut(cw, i) {
    setTimeout(() => {
      cw[i].className = 'letter out';
    }, i * 80);
  }

  animateLetterIn(nw, i) {
    setTimeout(() => {
      nw[i].className = 'letter in';
    }, 340 + i * 80);
  }
}
