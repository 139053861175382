/* eslint-disable class-methods-use-this */
import * as _ from 'lodash';
import SkillBlock from './models/SkillBlock';

export default class Skills {
  constructor(data, techs) {
    this.techs = techs;
    const root = document.getElementById('skill_dyn');

    data.forEach((element) => {
      root.appendChild(this.createElement(element));
    });
  }

  createElement(skillBlock) {
    const block = new SkillBlock(skillBlock);

    const wrapper = document.createElement('div');
    wrapper.className = block.className;

    wrapper.appendChild(this.createHeader(block.title));
    wrapper.appendChild(this.createContent(block.skills));

    return wrapper;
  }

  createHeader(header) {
    const div = document.createElement('div');
    div.className = 'project__header';
    div.innerText = header;

    return div;
  }

  createContent(skills) {
    const content = document.createElement('div');
    content.className = 'project__content';

    skills.forEach((skill) => {
      const finded = _.find(this.techs, ['id', skill]);
      if (finded) {
        const skillWrapper = document.createElement('div');
        skillWrapper.className = 'language flex flex1 row align-c';

        const skillName = document.createElement('span');
        skillName.className = 'flex1';
        skillName.innerHTML = `<a target="_blank" href="${finded.url}">${finded.name}</a>`;

        skillWrapper.appendChild(skillName);

        const rateWrapper = document.createElement('div');
        rateWrapper.className = 'flex align-c';

        for (let i = 0; i < 5; i++) {
          const rate = document.createElement('div');
          if (i < finded.rate) {
            rate.className = 'dot act';
          } else {
            rate.className = 'dot';
          }
          rateWrapper.appendChild(rate);
        }

        skillWrapper.appendChild(rateWrapper);
        content.appendChild(skillWrapper);
      }
    });
    return content;
  }
}
