export default class Tile {
  constructor(project) {
    this.id = project.id;
    this.lightHeading = project.heading.light;
    this.boldHeading = project.heading.bold;
    this.description = project.description;
    this.url = project.url;
    this.image = project.image;
    this.treeNode = project.treeNode;
  }
}
