/* eslint-disable class-methods-use-this */
import TweenMax from 'gsap/TweenMax';

export default class CustomCursor {
  constructor() {
    this.initializeValues();
    document.addEventListener('mousemove', (e) => {
      this.clientX = e.clientX;
      this.clientY = e.clientY;
    });

    requestAnimationFrame(this.render.bind(this));

    this.initHovers();
  }

  initializeValues() {
    this.cursor = document.querySelector('.square-cursor');
    this.cursorInner = document.querySelector('.square-cursor__inner');
    this.cursorBox = this.cursor.getBoundingClientRect();
    this.clientX = -100;
    this.clientY = -100;
    this.isStuck = false;
    this.stuckX = this.clientX;
    this.stuckY = this.clientY;
    this.cursorOriginals = {
      width: this.cursorInner.offsetWidth,
      height: this.cursorInner.offsetHeight
    };
  }

  render() {
    if (!this.isStuck) {
      TweenMax.set(this.cursor, {
        x: this.clientX - this.cursorBox.width / 2,
        y: this.clientY - this.cursorBox.height / 2
      });
    }
    requestAnimationFrame(this.render.bind(this));
  }

  handleMouseEnter(e) {
    // cursorBox = this.cursor.getBoundingClientRect();
    this.isStuck = true;
    this.stuckX = this.clientX;
    this.stuckY = this.clientY;
    const target = e.currentTarget;
    const linkBox = target.getBoundingClientRect();
    const style = window.getComputedStyle(target);

    TweenMax.to(this.cursor, 0.25, {
      x: linkBox.left + linkBox.width / 2 - this.cursorBox.width / 2,
      y: linkBox.top + linkBox.height / 2 - this.cursorBox.height / 2 - 0.5
    });
    TweenMax.to(this.cursorInner, 0.2, {
      rotation: 0,
      width: linkBox.width,
      height: linkBox.height,
      borderRadius: style.borderRadius
    });
  }

  handleMouseLeave() {
    this.isStuck = false;

    TweenMax.to(this.cursorInner, 0.25, {
      rotation: -45,
      width: this.cursorOriginals.width,
      height: this.cursorOriginals.height,
      borderRadius: '0px'
    });
  }

  initHovers() {
    const elems = [
      ...document.querySelectorAll('.nav__link'),
      ...document.querySelectorAll('.el__close-btn'),
      ...document.querySelectorAll('.close-wrapper')
    ];
    elems.forEach((item) => {
      item.addEventListener('mouseenter', this.handleMouseEnter.bind(this));
      item.addEventListener('mouseleave', this.handleMouseLeave.bind(this));
    });
  }
}
