/* eslint-disable no-new */
/* eslint-disable class-methods-use-this */

import Tile from './tile';

export default class Projects {
  constructor(data) {
    const root = document.getElementById('content_dyn');
    data.forEach((element) => {
      root.appendChild(this.createElement(element));
    });
    // this.d3Handler = new TreeD3Force(data);
  }

  createElement(project) {
    const tile = new Tile(project);
    const el = document.createElement('div');
    el.className = 'el';
    el.innerHTML = `
        <div class="el__overflow">
            <div class="el__inner">
                <div class="el__bg"></div>
                <div class="el__preview-cont">
                    <h2 class="el__heading f-light">${tile.lightHeading}<span class="f-bold">${tile.boldHeading}</span>
                    </h2>
                </div>
                <div class="el__content">
                    <div class="el__content__wrapper">
                        <div class="el__text">
                            <span class="f-light">${tile.lightHeading}<span class="f-bold">${tile.boldHeading}</span>
                            </span>
                        </div>
                        <div class="specs__wrapper">
                            <div class="container-specs"></div>
                            <p class="desc flex1">${tile.description}</p>
                            <svg class="tree_arch"></svg>
                        </div>
                        <div class="el__link">
                           ${this.getLink(tile.url)}
                        </div>
                        <div class="el__close-btn"></div>
                    </div>
                </div>
            </div>
        </div>
     `;
    return el;
    //  var elOFlow = document.createElement("div");
  }

  getLink(url) {
    if (url) {
      return `<div class="link__button">
                            
                                <a href="${url}" target="_blank" class="cursor-effect">
                                    more...&nbsp;
                                    <span class="shift">›</span>
                                </a>
                                <div class="mask"></div>
                            </div>`;
    }
    return '<div></div>';
  }
}
