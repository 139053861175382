module.exports = {
  init(anime) {
    const openResumeButton = document.getElementById('resume');
    const closeResumeButton = document.querySelector('.close-wrapper');
    const resumeWrapper = document.querySelector('.resume__grid');
    const scrollWrapper = document.getElementById('scroll-wrapper');
    const stickyYear = document.getElementById('sticky__year');
    const stickyCompany = document.getElementById('sticky__company');

    const years = Array.from(document.querySelectorAll('.timeline__section_header')).reverse();

    const companies = Array.from(document.querySelectorAll('.timeline__company')).reverse();

    const blockAnim = () => {
      resumeWrapper.classList.remove('hidden-block');
      return anime({
        targets: '.resume__grid',
        opacity: [0, 1],
        autoplay: false,
        easing: 'easeInOutQuad'
      });
    };

    const growAnim = (callback) => {
      let flag = true;

      return anime({
        targets: '.grow__line',
        width: [0, '100%'],
        easing: 'easeInOutQuad',
        delay: 200,
        autoplay: false,
        update: (anim) => {
          if (anim.progress >= 75 && anim.progress <= 85) {
            if (flag) {
              callback();
            }
            flag = false;
          }
        }
      });
    };

    const fadeTranslateAnimLeft = (callback) =>
      anime({
        targets: '.agent-1',
        opacity: [0, 1],
        easing: 'easeInOutQuad',
        translateX: [-50, 0],
        autoplay: false,
        complete: () => {
          callback().play();
        }
      });

    const fadeTranslateAnimTop = () =>
      anime({
        targets: '.agent-2',
        opacity: [0, 1],
        easing: 'easeOutElastic(2, .8)',
        translateY: [-20, 0],
        autoplay: false
      });

    const agentAnimHandler = () => {
      document.querySelector('.resume-close-btn').classList.add('active');
      fadeTranslateAnimLeft(fadeTranslateAnimTop).play();
      dotsAnim().play();
      growLineAnim(resumeHeaderAnim).play();
    };

    const resetAnims = () => {
      document.querySelectorAll('.col-block').forEach((el) => el.classList.add('hidden'));
      dotsAnim().reverse();
      growLineAnim().reverse();
      resumeHeaderAnim().reverse();
      fadeTranslateAnimTop().reverse();
      fadeTranslateAnimLeft().reverse();
      growAnim().reverse();
      blockAnim().reverse();
    };

    const dotsAnim = () =>
      anime({
        targets: '.dot.act',
        borderRadius: '3px',
        rotate: 45,
        backgroundColor: '#ff5252',
        easing: 'easeInOutQuad',
        autoplay: false,
        delay: anime.stagger(50)
      });

    const growLineAnim = (callback) => {
      let flag = true;
      return anime({
        targets: ['.grow_line'],
        width: [0, '100%'],
        easing: 'easeInOutQuad',
        autoplay: false,
        update: (anim) => {
          if (anim.progress >= 75 && anim.progress <= 85) {
            if (flag) {
              callback().play();
            }
            flag = false;
          }
        }
      });
    };

    const resumeHeaderAnim = () => {
      const left = Array.from(document.querySelectorAll('.resume__header_left')).reverse();
      const right = Array.from(document.querySelectorAll('.resume__header_right'));
      return anime({
        targets: [...left, ...right],
        top: ['-2.22em', '-1.05em'],
        opacity: [0, 1],
        textShadow: ['0px 0px 0px rgba(0, 0, 0, 0)', '2px -2px 3px rgba(0, 0, 0, 0.24)'],
        easing: 'easeInOutQuad',
        autoplay: false,
        delay: anime.stagger(50),
        complete: () => {
          document.querySelectorAll('.col-block').forEach((el) => el.classList.remove('hidden'));
        }
      });
    };

    const isScrolledIntoView = (el) => el.getBoundingClientRect().bottom <= scrollWrapper.getBoundingClientRect().top;

    const handleStickyStuff = (target, list) => {
      const { length } = list;
      for (let i = 0; i < length; i++) {
        if (isScrolledIntoView(list[i])) {
          target.innerText = list[i].innerText;
          break;
        }
      }
    };

    const scrollListener = () => {
      handleStickyStuff(stickyYear, years);
      handleStickyStuff(stickyCompany, companies);
    };

    const closeListener = () => {
      anime({
        targets: '.resume__grid',
        opacity: [1, 0],
        easing: 'easeInOutQuad',
        complete: () => {
          document.querySelector('.resume-close-btn').classList.remove('active');
          resetAnims();
          stickyYear.innerText = '';
          stickyCompany.innerText = '';
          resumeWrapper.classList.add('hidden-block');
          document.querySelector('.loader').classList.remove('loader--active');
          closeResumeButton.removeEventListener('mouseup', closeListener);
          scrollWrapper.removeEventListener('mouseup', scrollListener);
        }
      });
    };

    const openListener = () => {
      closeResumeButton.addEventListener('mouseup', closeListener);
      scrollWrapper.addEventListener('scroll', scrollListener);

      document.querySelector('.loader').classList.add('loader--active');
      setTimeout(() => {
        blockAnim().play();
        growAnim(agentAnimHandler).play();
      }, 900);
    };

    openResumeButton.addEventListener('mouseup', openListener);
  }
};
