/* eslint-disable no-new */
/* eslint-disable no-undef */
import anime from 'animejs';
import experienceData from '../resources/experience';
import projectData from '../resources/projects';
import skillsData from '../resources/skills';
import techsData from '../resources/techs';
import style from '../styles/main.scss';
import CustomCursor from './v2/models/CustomCursor';
import Experiences from './v2/models/Experiences';
import HoverButton from './v2/models/HoverButton';
import Panel from './v2/models/Panel';
import Projects from './v2/models/Projects';
import WordAnimation from './v2/models/WordAnimation';
import projectSlides from './v2/project_slides';
import resume from './v2/resume';
import Skills from './v2/skills';
import { DOM } from './v2/utils/panel-utils';
import utils from './v2/utils/utils';

function init() {
  utils.checkBrowser();
  utils.setupServiceWorker();

  new Panel(DOM.intro.querySelector('.intro__box'));
  new WordAnimation('word');
  new HoverButton(document.querySelector('.intro__logo .icon--arrowup'));
  new Experiences(experienceData, techsData);
  new Projects(projectData);
  new Skills(skillsData, techsData);
  projectSlides.init();
  resume.init(anime);

  console.log(style);
  new CustomCursor();
}

// console.log("PROJECTS", projects);
// const projects = new Projects();

init();
