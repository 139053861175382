import { Elastic, Power2, TweenMax } from 'gsap/TweenMax';

export default class HoverButton {
  constructor(element) {
    this.el = element;
    this.hover = false;
    this.calculatePosition();
    this.attachEventsListener();
  }

  calculatePosition() {
    TweenMax.set(this.el, {
      x: 0,
      y: 0,
      scale: 1
    });
    const box = this.el.getBoundingClientRect();
    this.x = box.left + box.width * 0.5;
    this.y = box.top + box.height * 0.5;
    this.width = box.width;
    this.height = box.height;
  }

  attachEventsListener() {
    window.addEventListener('mousemove', this.onMouseMove.bind(this));
    window.addEventListener('resize', this.calculatePosition.bind(this));
  }

  onMouseMove(e) {
    let hover = false;
    const hoverArea = this.hover ? 0.7 : 0.5;
    const x = e.clientX - this.x;
    const y = e.clientY - this.y;
    const distance = Math.sqrt(x * x + y * y);
    if (distance < this.width * hoverArea) {
      hover = true;
      if (!this.hover) {
        this.hover = true;
      }
      this.onHover(e.clientX, e.clientY);
    }

    if (!hover && this.hover) {
      this.onLeave();
      this.hover = false;
    }
  }

  onHover(x, y) {
    TweenMax.to(this.el, 0.4, {
      x: (x - this.x) * 0.4,
      y: (y - this.y) * 0.4,
      scale: 1.15,
      ease: Power2.easeOut
    });

    this.el.style.zIndex = 10;
  }

  onLeave() {
    TweenMax.to(this.el, 0.7, {
      x: 0,
      y: 0,
      scale: 1,
      ease: Elastic.easeOut.config(1.2, 0.4)
    });

    this.el.style.zIndex = 1;
  }
}
