/* eslint-disable no-console */
module.exports = {
  checkBrowser() {
    document.documentElement.className = 'js';
    if (!(window.CSS && window.CSS.supports && window.CSS.supports('font-weight', 'var(--cool-red)'))) {
      // eslint-disable-next-line no-alert
      alert('Please view this demo in a modern browser that supports CSS Variables.');
    }
  },
  setupServiceWorker() {
    window.addEventListener('load', () => {
      navigator.serviceWorker
        .register('/service-worker.js')
        .then((registration) => {
          console.log('SW registered: ', registration);
        })
        .catch((registrationError) => {
          console.log('SW registration failed: ', registrationError);
        });
    });
  }
};
