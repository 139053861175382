/* eslint-disable class-methods-use-this */
import * as _ from 'lodash';

export default class Experiences {
  constructor(data, techs) {
    this.techs = techs;
    console.log(this.techs);
    console.log(data);
    console.log(_);
    const root = document.getElementById('exp_dyn');
    data.forEach((element) => {
      root.appendChild(this.createElement(element));
    });
  }

  // findTech()

  //   createCompanyNode(company) {}

  createRolesNodes(roles) {
    const div = document.createElement('div');
    div.className = 'techs row';

    roles.forEach((role) => {
      const roleSpan = document.createElement('span');
      roleSpan.className = 'tag is-rounded';
      roleSpan.innerText = role;
      div.appendChild(roleSpan);
    });

    return div.innerHTML;
    //   return div.ht
  }

  createTechNodes(techs) {
    const div = document.createElement('div');
    div.className = 'techs row';

    techs.forEach((tech) => {
      const techFinded = _.find(this.techs, ['id', tech]);
      if (techFinded) {
        const techSpan = document.createElement('span');
        techSpan.className = 'tag is-rounded nav__link';

        techSpan.innerHTML = `
          <a target="_blank" href="${techFinded.url}">
                     ${techFinded.name}${techFinded.version}
                   </a>
          `;
        div.appendChild(techSpan);
      }
    });

    return div.innerHTML;
    //   return div.ht
  }

  createProjectNode(project) {
    const div = document.createElement('div');
    div.className = 'project';

    const projHeader = document.createElement('div');
    projHeader.className = 'project__header';

    if (project.url) {
      const urlHead = document.createElement('a');
      urlHead.className = 'icon-expand nav__link';
      urlHead.setAttribute('target', 'blank');
      urlHead.setAttribute('href', project.url);
      projHeader.appendChild(urlHead);
    }

    const projMainRole = document.createElement('span');
    projMainRole.innerText = project.mainRole;

    projHeader.appendChild(projMainRole);

    div.appendChild(projHeader);

    const projContent = document.createElement('div');
    projContent.className = 'project__content';
    projContent.innerHTML = `       
        ${project.description}
        <span class="title row">Role:</span>
        <div class="techs row">
          ${this.createRolesNodes(project.roles)}
        </div>
        <span class="title row">Technologies:</span>
        <div class="techs row">
          ${this.createTechNodes(project.techs)}
        </div>      
          `;

    const projFooter = document.createElement('div');
    projFooter.className = 'row just-e align-c project__footer';
    projFooter.innerHTML = `
      <div>
        <em class="icon-calendar"></em>
        <span>started on ${project.startDate}</span>
      </div>          
    `;

    div.appendChild(projContent);
    div.appendChild(projFooter);

    return div.innerHTML;
  }

  createElement(experience) {
    const li = document.createElement('li');
    const yearDiv = document.createElement('div');
    yearDiv.setAttribute('id', experience.year);
    yearDiv.className = 'timeline__section_header';
    yearDiv.innerText = experience.year;

    li.appendChild(yearDiv);

    experience.companies.forEach((company) => {
      const wrapperDiv = document.createElement('div');
      wrapperDiv.className = 'timeline__section_content';

      const compDiv = document.createElement('div');
      compDiv.className = 'timeline__company  nav__link';
      compDiv.innerHTML = `
         <a target="_blank" href="${company.url}">
            ${company.name}
          </a>
      `;

      wrapperDiv.appendChild(compDiv);

      const projWrapper = document.createElement('div');
      projWrapper.className = 'timeline__content';

      company.projects.forEach((project) => {
        const projDiv = document.createElement('div');
        projDiv.className = 'project';
        projDiv.innerHTML = this.createProjectNode(project);

        projWrapper.appendChild(projDiv);
      });

      wrapperDiv.appendChild(projWrapper);
      li.appendChild(wrapperDiv);
    });

    return li;
  }
}
